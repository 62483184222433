/**
 * Gets the dynamically defined property from an object
 * - dot notation can be used
 *
 * @param {Object} obj  - source object
 * @param {String} path - path to property in dot format ie. 'loan.balance'
 *
 * @return - source object property value
 *
 * @example - Get the value of object.nested.property
 * - getDynamicProperty(object, 'nested.property');
 *
 * @example - Get the value of object.property
 * - getDynamicProperty(object, 'property');
 */
const getDynamicProperty = (obj, path) => path
  .split('.')
  .reduce((acc, part) => acc && acc[part], obj);

export default getDynamicProperty;
