<template>
  <cw-page
    icon="business"
    class="cw-business-loan-offer"
  >
    <template #hero-title>
      <translate>
        Your Loan offer
      </translate>
    </template>
    <template>
      <v-row v-if="offers.length === 1">
        <v-col
          md="6"
          cols="12"
        >
          <h3 class="text-subtitle-1 font-weight-bold mb-1">
            <translate>Accepting the offer</translate>
          </h3>
          <p v-if="isSmePosInstalmentLoan">
            {{ smePosInstalmentLoanOfferText }}
          </p>
          <p v-else>
            {{ offerText }}
          </p>
          <v-alert
            border="left"
            type="info"
            dense
            text
          >
            {{ loanAdvisorText }}
          </v-alert>
        </v-col>
        <v-col
          class="
            cw-border-container
            cw-border-container--secondary
            cw-border-container--md-center
          "
          md="6"
          cols="12"
        >
          <div class="cw-application__table">
            <table id="cw-business-loan-offer__table">
              <tbody>
                <tr
                  v-for="row in offerRows"
                  :key="row.key"
                >
                  <td>{{ row.title }}</td>
                  <td class="text-right">
                    <b v-if="row.filter">
                      {{ $filters[row.filter](row.value) }}{{ row.suffix(row.value) }}
                    </b>
                    <b v-else>
                      {{ row.value }} {{ row.suffix(row.value) }}
                    </b>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </v-col>
      </v-row>

      <template v-else-if="offers.length > 1">
        <v-row>
          <v-col>
            <p v-if="isSmePosInstalmentLoan">
              {{ smePosInstalmentLoanOfferText }}
            </p>
            <p v-else>
              {{ offerText }}
            </p>
            <v-alert
              border="left"
              type="info"
              dense
              text
            >
              {{ loanAdvisorText }}
            </v-alert>
          </v-col>
        </v-row>
        <cw-price-cards
          :offer-id.sync="selectedOfferId"
          :offers="offers"
          is-business-loan
        />

        <v-slide-y-transition origin="center top">
          <v-row
            v-if="selectedOfferId === null && submitted"
            class="grey lighten-3"
            justify="center"
          >
            <v-col class="text-center">
              <div class="cw-application--error">
                <div
                  id="errors--offers"
                  class="error--text"
                >
                  <translate>
                    Select an offer to continue
                  </translate>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-slide-y-transition>
      </template>

      <v-row v-else class="relative py-12">
        <v-col cols="12">
          <cw-form-loader/>
        </v-col>
      </v-row>
    </template>

    <template #actions>
      <v-spacer/>
      <v-btn
        id="submit"
        :dark="!$wait.waiting('SUBMIT_FORM')"
        :disabled="$wait.waiting('SUBMIT_FORM')"
        :loading="$wait.waiting('SUBMIT_FORM')"
        color="purple-one darken-2"
        @click="
          submit();
          $eventLogger.clickEvent($event);
        "
      >
        <translate>Continue</translate>
      </v-btn>
    </template>
  </cw-page>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { programs } from '@shared/config';
import getDynamicProperty from '@shared/utils/getDynamicProperty';
import { DataLayer } from '@shared/services/dataLayer';

export default {
  name: 'CwBusinessLoanOffer',

  components: {
    'cw-price-cards': () => import('@shared/components/LoanOfferPriceCards'),
  },

  data: () => ({
    selectedOfferId: null,
    programs,
    submitted: false,
  }),

  computed: {
    ...mapGetters({
      locale: 'application/locale',
      product: 'application/getProduct',
    }),

    ...mapState({
      offers: state => state.application.offers,
    }),

    isSmePosInstalmentLoan() {
      return [
        'Saldo.Finland.SmePosInstalmentLoan',
      ].includes(this.product.productName);
    },

    loanAdvisorText() {
      return this.$gettext('If you have any questions about the offers received by your company you can contact us at number 010 7737 440.');
    },

    offerRows() {
      const [offer] = this.offers;
      const { $gettext, $ngettext } = this;

      const items = [{
        title: $gettext('Loan amount'),
        filter: 'currency',
        filterOptions: { locale: this.locale },
        key: 'loanLimit',
        suffix: () => '',
      }, {
        title: $gettext('The loan period'),
        filter: null,
        filterOptions: { locale: this.locale },
        key: 'numberOfInstalments',
        suffix: value => $ngettext('mo', 'mos', value),
      }, {
        title: $gettext('Monthly Instalment'),
        filter: 'currency',
        filterOptions: { locale: this.locale },
        key: 'instalmentAmount',
        suffix: () => '',
      }, {
        title: $gettext('Monthly interest rate'),
        filter: 'percent',
        key: 'monthlyInterestRate',
        suffix: () => '',
      }, {
        title: $gettext('Opening fee'),
        filter: 'currency',
        filterOptions: { locale: this.locale },
        key: 'deliveryFeeTotal',
        suffix: () => '',
      }, {
        title: $gettext('Account management fee'),
        filter: 'currency',
        filterOptions: { locale: this.locale },
        key: 'maintenanceFee',
        suffix: () => '',
      }, {
        title: $gettext('Amount to be paid'),
        filter: 'currency',
        filterOptions: { locale: this.locale },
        key: 'instalmentTotals.instalmentAmount',
        suffix: () => '',
      }];

      // add values and return rows
      return items.map(item => ({ ...item, value: getDynamicProperty(offer, item.key) }));
    },

    offerText() {
      return this.offers.length === 1
        ? this.$gettext('Check the loan amount and payment terms carefully. By proceeding you accept the offer and proceed to sign the loan agreement. At the same time, we will send a draft agreement to your email.')
        : this.$gettext('Choose the appropriate offer from the options. By continuing, you accept the offer and proceed to sign the loan agreement.');
    },

    smePosInstalmentLoanOfferText() {
      return this.offers.length === 1
        ? this.$gettext('Check the loan amount and payment terms carefully. By proceeding you accept the offer and proceed to sign the loan agreement. At the same time, we will send a draft agreement to your email.')
        : this.$gettext('Choose the appropriate offer from the options. By continuing, you accept the offer and proceed to sign the loan agreement.');
    },
  },

  async created() {
    this.$bus.$on('SELECTED_OFFER_ID__SET', id => this.setSelectedOfferId(id));

    await this.loadLoanOffers();

    if (this.offers.length === 1) {
      this.setSelectedOfferId(this.offers[0].id);
    }

    this.$dataLayer.PushToECommerce({
      params: {
        action: 'loanOffersB2b',
      },
    });
    this.$dataLayer.PushToECommerce({ params: { action: 'impressionsB2b' } });

    if (this.offers.length > 1) {
      this.onImpressionView();
    }
  },

  methods: {
    ...mapActions({
      loadLoanOffers: 'application/loadLoanOffers',
      onImpressionView: 'dataLayer/onImpressionView',
      setSelectedOffer: 'application/setSelectedOffer',
      submitSelectedOffer: 'application/submitSelectedOffer',
    }),

    setSelectedOfferId(id) {
      this.selectedOfferId = id;

      const selectedOffer = this.offers.find(offer => offer.id === id);

      this.setSelectedOffer(selectedOffer);
    },

    async submit() {
      try {
        this.submitted = true;
        this.$dataLayer.PushToECommerce({ params: { action: 'b2bStepValidationClick' } });

        DataLayer.onValidationClick();

        if (this.selectedOfferId === null) {
          this.$vuetify.goTo('.offerSelectionButton', {
            duration: 200,
            offset: -120,
            easing: 'easeInOutCubic',
          });

          DataLayer.onValidationFail();

          return;
        }

        this.$dataLayer.PushToECommerce({ params: { action: 'b2bStepValidationSubmit' } });
        this.$wait.start('SUBMIT_FORM');

        const selectedOffer = this.offers.find(offer => offer.id === this.selectedOfferId);

        const params = {
          formParams: {
            selectedOffer,
          },
          routineParams: {
            uri: 'executeRoutine',
            routine: 'Submit Loan Offer Selection',
          },
        };
        await this.submitSelectedOffer(params);
      } catch (e) {
        this.$wait.end('SUBMIT_FORM');
      }
    },
  },
};
</script>
